// src/Layout.js
import React from 'react';
import NavBar from './navbar';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Experience from './Experience';
import Contact from './Contact';
import SocialLinks from './SocialLinks';

const Layout = () => {
  return (
    <div>
      <NavBar />
      <Home />
      <About />
      <Portfolio />
      <Experience />
      <Contact />
      <SocialLinks />
    </div>
  );
};

export default Layout;
