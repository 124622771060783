// import About from "./components/About";
// import Contact from "./components/Contact";
// import Experience from "./components/Experience";
// import Home from "./components/Home";
// import NavBar from "./components/navbar";
// import Portfolio from "./components/Portfolio";
// import SocialLinks from "./components/SocialLinks";
import ContactInquiries from "./components/Enquiry";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
      <Routes>
          <Route path="/" element={<Layout />} /> {/* Root route */}
          <Route path="/contactus" element={<ContactInquiries />} /> {/* Contact inquiries route */}
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
