import axios from "axios";
import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  
  const handleOnChange = (key, data) => {
    setFormData({ ...formData, [key]: data });
  };

  const api = async (e) => {
    e.preventDefault();
    
    const url = "https://api.devpravesh.in/api/contact/";

    try {
      const result = await axios.post(url, formData);
      console.log(result);

      // Display success message
      setSuccessMessage("Form submitted successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      name="contact"
      className="w-full bg-gradient-to-b from-black to-gray-800 p-4 text-white pt-28 md:pt-20"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Contact
          </p>
          <p className="py-6">Submit the form below to get in touch with me</p>
        </div>

        {successMessage && (
          <div className="bg-green-500 text-white p-4 mb-4 rounded-md">
            {successMessage}
          </div>
        )}

        <div className="flex justify-center items-center">
          <form className="flex flex-col w-full md:w-1/2">
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="text"
              name="email"
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea
              name="message"
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            ></textarea>

            <button
              className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
              onClick={api}
            >
              Let's talk
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
