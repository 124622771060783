import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        <p className="text-xl mt-20">
          As a Full Stack Software Developer at Meero Digital Labs Private Limited, 
          I have been with the company for just over a year. During this time, 
          I have significantly contributed to over a many internal projects 
          and have also taken on the responsibility of overseeing one of our major products.
        </p>

        <br />

        <p className="text-xl">
        Before joining Meero Digital Labs, I worked for 1 year and 
        8 months at Allarch Healthcare and InkStudio Pvt Limited. 
        During this time, I refined my skills in mobile app development. 
        I am particularly interested in focusing on mobile software development, an area where I know your company excels.
        </p>
      </div>
    </div>
  );
};

export default About;
