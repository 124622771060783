// src/ContactInquiries.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ContactInquiries = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('https://api.devpravesh.in/api/contacts');
        // Sort contacts in descending order based on _id
        const sortedContacts = response.data.sort((a, b) => b._id.localeCompare(a._id));
        setContacts(sortedContacts);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-center text-red-600">{error}</p>;

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold mb-4">Contact Inquiries</h1>
      <ul className="space-y-4">
      {contacts.map(contact => (
          <li key={contact._id} className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
            <h2 className="text-xl font-semibold mb-2">{contact.name}</h2>
            <p className="text-gray-700 mb-1"><strong>Email:</strong> {contact.email}</p>
            <p className="text-gray-700 mb-1"><strong>Message:</strong> {contact.message}</p>
            <p className="text-gray-500 text-sm"><strong>Contacted on:</strong> {new Date(contact.createdAt).toLocaleString()}</p> {/* Display the creation date */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactInquiries;
